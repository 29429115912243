import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Grid from "../components/grid";

const ArtistPage = ({ data }) => (
    <div>
    <Seo title="News" />
    <Grid data={data.allContentfulArtist.nodes} />
    </div>
);

export const query = graphql`
  {
    allContentfulArtist (sort: { fields: [order], order: DESC }) {
      nodes {
        name
        slug
        order
        image {
          fixed(width: 800, height: 800) {
            src
          }
        }
      }
    }
  }
`;

export default ArtistPage;
